// Generated by Framer (9f979fb)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["vpYxO7UKb"];

const serializationHash = "framer-fnSGA"

const variantClassNames = {vpYxO7UKb: "framer-v-v7dtrg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const transition1 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const animation = {opacity: 1, rotate: 360, scale: 2.4, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "vpYxO7UKb", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-v7dtrg", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"vpYxO7UKb"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1fymasr"} data-framer-name={"Standard"} layoutDependency={layoutDependency} layoutId={"wDKfHXHcV"} style={{background: "linear-gradient(147deg, var(--token-2930fc96-2a19-4c51-bdb3-fa6e89c47e8a, rgb(61, 90, 241)) /* {\"name\":\"Primary 100\"} */ 0%, var(--token-322bafb3-8121-4ee2-bc03-88b736aeb081, rgb(203, 93, 255)) /* {\"name\":\"Secondary\"} */ 100%)", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, opacity: 0.8}} transformTemplate={transformTemplate1}/><MotionDivWithFX __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"loop"} __framer__loopTransition={transition1} __perspectiveFX={false} __smartComponentFX __targetOpacity={0.4} className={"framer-djldj3"} data-framer-name={"Animate"} layoutDependency={layoutDependency} layoutId={"ENGEsJeP8"} style={{background: "linear-gradient(147deg, rgb(61, 90, 241) 0%, var(--token-322bafb3-8121-4ee2-bc03-88b736aeb081, rgb(203, 93, 255)) /* {\"name\":\"Secondary\"} */ 100%)", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, opacity: 0.4}} transformTemplate={transformTemplate1}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-fnSGA[data-border=\"true\"]::after, .framer-fnSGA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fnSGA.framer-1tau9cl, .framer-fnSGA .framer-1tau9cl { display: block; }", ".framer-fnSGA.framer-v7dtrg { height: 40px; overflow: visible; position: relative; width: 40px; }", ".framer-fnSGA .framer-1fymasr { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 14px); left: 50%; overflow: hidden; position: absolute; top: 50%; width: 14px; will-change: var(--framer-will-change-override, transform); }", ".framer-fnSGA .framer-djldj3 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 25px); left: 50%; overflow: hidden; position: absolute; top: 50%; width: 25px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercR4fdDT1h: React.ComponentType<Props> = withCSS(Component, css, "framer-fnSGA") as typeof Component;
export default FramercR4fdDT1h;

FramercR4fdDT1h.displayName = "Pulse";

FramercR4fdDT1h.defaultProps = {height: 40, width: 40};

addFonts(FramercR4fdDT1h, [])